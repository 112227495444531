<template>
    <section id="workingArea">
        <div class="text-center">
            <h4 class="q-mb-sm" style="color: rgba(171, 196, 255, 0.9);">StarSwap | Liquidity Generation Event</h4>
            <p v-if="!isMetaMaskConnected" class="q-mt-sm token">
                Please connect wallet to check the status.
            </p>
            <p v-if="isMetaMaskConnected && resultPeriodEndDate != '0'" class="token">
                LGE ends in {{resultPeriodEndDate}}
            </p>
            <p v-if="isMetaMaskConnected && resultPeriodEndDate == '0'" class="token">
                This event has ended.
            </p>
            <div class="subBlock" 
                style="color:rgba(255, 255, 255, 0.5) !important;">
                <div class="row">
                    <p style="color: rgba(171, 196, 255, 0.9);">Welcome to the ^^Star Launch^^. 4.4M $STAR tokens are available during the LGE and you get your fair share based on the ASTR you send.
                        100% of the ASTR collected is added as liquidity, along with 3.6M $STAR. This will make the price of $STAR go 23% higher immediately than the effective LGE price.
                    </p>
                   
                </div>
                <div v-if="resultPeriodEndDate != '0'"  
                    class="justify-center flex items-center q-mt-sm">
                    <div class="sol explorer q-mt-sm half"> 
                        <q-input
                            style="margin-left:0; margin-right:0;"
                            standout="bg-indigo-5" type="text" 
                            placeholder="0.00" v-model="amountASTR"
                            :error-message="errorMessage" :error="errorMessage!=null" :bottom-slots="false" :hide-bottom-space="true">
                            <template v-slot:prepend>
                                <div class="dropdown">
                                    <button @click="showPercent()" 
                                        class="dropbtn mx-2 dropdown-toggle">
                                        {{currentPercent}}%</button>
                                    
                                    <div id="percent" class="percent dropdown-content" 
                                        v-click-outside="onClickOutside1">
                                        <ul>
                                            <li @click="setPercent(0)">25%</li>
                                            <li @click="setPercent(1)">50%</li>
                                            <li @click="setPercent(2)">75%</li>
                                            <li @click="setPercent(3)">100%</li>
                                        </ul>
                                    </div>
                                </div>
                            </template>
                        </q-input>
                    </div>
                </div>
                <div v-if="resultPeriodEndDate != '0'"  class="justify-center flex items-center q-mt-sm" style="margin-bottom:15px;">
                    <div class="mintBtn half" v-if="!isMetaMaskConnected" @click="connectWallet">
                        Connect to wallet</div>
                    <div class="btn" v-if="isMetaMaskConnected" @click="depositASTR" style="font-size:16px; font-weight:600; background: linear-gradient(135deg, #204374 0%,#302e74 100%); width: 50%; border-radius:4px;">
                        Send ASTR </div>
                </div>
                <div class="justify-center flex items-center q-mt-md">
                    <div v-if="isMetaMaskConnected && resultPeriodEndDate != '0'" class="q-px-lg">
                        <div class="justify-center flex items-center q-my-sm sol">
                            <div>
                                <span class="avatar">
                                    <img :src="require(`@/assets/images/tokens/astr.png`)" />
                                </span>
                            </div>
                            <div class="justify-center flex items-baseline q-pl-sm">
                                <h4 class="q-my-none" style="color: rgba(171, 196, 255, 0.9);">
                                <div>{{totalASTR}}</div>
                                </h4>
                                <div class="q-pl-sm token">ASTR</div>
                            </div>
                        </div>
                        <p class="text-center label">Total Collected</p>
                    </div>
                    <div v-if="isMetaMaskConnected && resultPeriodEndDate != '0'" class="q-px-lg">
                        <div class="justify-center flex items-center q-my-sm sol">
                            <div>
                                <span class="avatar">
                                    <img :src="require(`@/assets/images/tokens/star.png`)" />
                                </span>
                            </div>
                            <div class="justify-center flex items-baseline q-pl-sm">
                                <h4 class="q-my-none" style="color: rgba(171, 196, 255, 0.9);">{{getDecimalData(starPrice)}}</h4>
                                <div class="q-pl-sm token">ASTR</div>
                            </div>
                        </div>
                        <p class="text-center label">Current Price</p>
                    </div>
                    <div v-if="isMetaMaskConnected" class="col-12 col-md-6 q-mt-md">
                        <div class="label_left q-mt-sm q-mx-md text-center">
                            <span class="label">ASTR You Sent</span>
                            <div>
                                <span class="label">{{userSharedASTR}} </span>
                                <span class="token">ASTR</span>
                            </div>
                        </div>
                    </div>
                    <div v-if="isMetaMaskConnected" class="col-12 col-md-6 q-mt-md">
                        <div class="label_right q-mt-sm q-mx-md text-center">
                            <div class="label">
                                <span>Claimed ☆ </span>
                                <span>Claimable</span>
                                <span> ☆ </span>
                                <span>Total</span>
                            </div>
                            <div class="label">
                                <span>{{userClaimedSTAR}}</span>
                                <span> ☆ {{userSharedSTAR}}</span>
                                <span> ☆ {{userTotalSharedSTAR}} </span>
                                <span class="token">STAR</span>
                            </div>
                        </div>
                    </div>
                </div>    
            </div>

            <div class="q-mb-md subBlock">
                <div v-if="isMetaMaskConnected" class="q-mt-sm flex justify-center items-baseline">
                    <div class="btn" @click="detail">&nbsp;&nbsp;Details&nbsp;&nbsp;</div>
                    <div class="btn q-mx-md" @click="contract">Contract</div>
                    <div class="btn" @click="claim">&nbsp;&nbsp;Claim&nbsp;&nbsp;</div>
                </div>
                <p v-if="isMetaMaskConnected" class="text-center q-mt-sm event-message">Claiming Date: {{resultBeginDate}} ~ {{resultEndDate}}</p>
                <div class="text-center token" style="margin-top:10px;">
                    <p v-if="isMetaMaskConnected">First 25% of your Star token allocation will be unlocked on 7th August 7AM UTC.
                    </p>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import BigNumber from 'bignumber.js'
    export default {
        components: {},
        data() {
            return {
                amountASTR: null,
                percents: [
                    '25', '50', '75', '100'
                ],
                currentPercent: 100
            }
        },
        computed: {
            maxASTR() {
                if(this.$store.state.account) {
                    return BigNumber(this.$store.state.balance.amountASTR)
                        .shiftedBy(-18);
                }
                return 0;
            },
            isMetaMaskConnected() {
                return this.$store.state.account!=null;
            },
            resultBeginDate() {
                let date = this.$store.state.liquiditygenerator.vestingBegin;
                const options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' }
                const dtf = new Intl.DateTimeFormat('en-US', options);
                return dtf.format(date);
            },
            resultEndDate() {
                let date = this.$store.state.liquiditygenerator.vestingEnd;
                const options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' }
                const dtf = new Intl.DateTimeFormat('en-US', options);
                return dtf.format(date);
            },
            resultPeriodEndDate() {
                let enddate = this.$store.state.liquiditygenerator.periodEnd;

                let currentTime = Date.now();
                var difference=enddate - currentTime;

                if (difference < 0)
                    return "0"; 

                var daysDifference = Math.floor(difference/1000/60/60/24);
                difference -= daysDifference*1000*60*60*24;

                var hoursDifference = Math.floor(difference/1000/60/60);
                difference -= hoursDifference*1000*60*60;

                var minutesDifference = Math.floor(difference/1000/60);
                difference -= minutesDifference*1000*60;

                var result = daysDifference.toString() + " days " +  hoursDifference.toString() + " hours " + minutesDifference.toString() + " minutes";

                return result;
            },
            errorMessage() {
                if(BigNumber(this.amountASTR).isGreaterThan(this.maxASTR)) {
                    return "INSUFFICIENT ACCOUNT BALANCE";
                }
                else if (BigNumber(this.amountASTR) < 500){
                    return "MINIMUM REQUIREMENT IS 500 ASTR";
                }
                else if (BigNumber(this.amountASTR) > 100000){
                    return "MAXIMUM AMOUNT IS 100K ASTR";
                }
                return null;
            },
            totalASTR() {
                if(this.$store.state.liquiditygenerator.totalASTRShares > 0)
                    return this.getDecimalData(BigNumber(this.$store.state.liquiditygenerator.totalASTRShares)
                            .shiftedBy(-18));
                return '0.00';
            },
            starPrice() {
                if(this.$store.state.liquiditygenerator.starPriceInLGE > 0)
                    return BigNumber(this.$store.state.liquiditygenerator.starPriceInLGE);
                return BigNumber(0);
            },
            userSharedASTR() {
                if(this.$store.state.liquiditygenerator.recipientInfo != null && 
                    this.$store.state.liquiditygenerator.recipientInfo[0] > 0)
                    return this.getDecimalData(BigNumber(this.$store.state.liquiditygenerator.recipientInfo[0])
                            .shiftedBy(-18));
                return '0.00'
            },
            userTotalSharedSTAR() {
                let total;
                let userShare;
                if(this.$store.state.liquiditygenerator.totalASTRShares > 0)
                    total = BigNumber(this.$store.state.liquiditygenerator.totalASTRShares).shiftedBy(-18);
                else
                    return '0.00'

                if(this.$store.state.liquiditygenerator.recipientInfo != null)
                    userShare = BigNumber(this.$store.state.liquiditygenerator.recipientInfo[0]).shiftedBy(-18);
                else
                    return '0.00'

                var share = userShare * 100 / total;
                var balance = this.$store.state.liquiditygenerator.normalVestingSTAR * share / 100;

                return this.getDecimalData(BigNumber(balance).shiftedBy(-18));
            },
            userSharedSTAR() {
                let currentTime = Date.now();
                let date = this.$store.state.liquiditygenerator.vestingBegin;

                if (currentTime < date)
                    return '0.00'

                if(this.$store.state.liquiditygenerator.totalASTRShares <= 0)
                    return '0.00'

                if(this.$store.state.liquiditygenerator.recipientInfo != null && 
                    this.$store.state.liquiditygenerator.claimableRewards > 0)
                    return this.getDecimalData(BigNumber(this.$store.state.liquiditygenerator.claimableRewards)
                            .shiftedBy(-18));
                else
                    return '0.00'
            },
            userClaimedSTAR() {
                if(this.$store.state.liquiditygenerator.recipientInfo != null && 
                    this.$store.state.liquiditygenerator.recipientInfo[2] > 0)
                    return this.getDecimalData(BigNumber(this.$store.state.liquiditygenerator.recipientInfo[2])
                            .shiftedBy(-18));
                else
                    return '0.00';
            },
        },
        mounted() {
            this.$store.commit('read_liquiditygenerator');
        },
        methods: {
            connectWallet() {                
                this.$store.dispatch('connect');              
            },
            getMaxASTR() {
                this.amountASTR = this.maxASTR;
            },
            depositASTR() {
                this.$store.dispatch('depositASTR',{
                    amount:this.amountASTR
                });
            },    
            detail() {
                window.open('https://starswap.net/blog/starswap-dex-launches-on-the-7th-of-july-at-7am-utc-7-7-7/', '_blank');
            },   
            contract() {
                window.open('https://starswap.net/blog/starswap-dex-launches-on-the-7th-of-july-at-7am-utc-7-7-7/', '_blank');
            },   
            claim() {
                this.$store.dispatch('claimSTAR');
            },
            showPercent() {
                document.getElementById("percent").classList.toggle("show");
            },
            setPercent(value) {
                this.currentPercent = this.percents[value];

                if (value == 0)
                    this.amountASTR = BigNumber(this.maxASTR).div(4);
                else if (value == 1)
                    this.amountASTR = BigNumber(this.maxASTR).div(2);
                else if (value == 2)
                    this.amountASTR = BigNumber(this.maxASTR).times(3).div(4);
                else
                    this.amountASTR = BigNumber(this.maxASTR);

                if (this.amountASTR == '0')
                    this.amountASTR = '0.00';

                 document.getElementById("percent").classList.toggle("show");

            },
            onClickOutside1 (event) {
                if (!event.target.matches('.dropbtn')) {
                    let dropdowns = document.getElementsByClassName("dropdown-content");
                    let i;
                    for (i = 0; i < dropdowns.length; i++) {
                        let openDropdown = dropdowns[i];
                        if (openDropdown.classList.contains('show')) {
                            openDropdown.classList.remove('show');
                        }
                    }
                }
            },
            getDecimalData(value) {
                let limit_xxx = BigNumber(1000);
                let limit_xx = BigNumber(1);
                let limit_x = BigNumber(1).shiftedBy(-3);
                let limit_sm = BigNumber(1).shiftedBy(-6);
                let limit_md = BigNumber(1).shiftedBy(-9);
                let limit_lg = BigNumber(1).shiftedBy(-12);

                if (limit_lg.isGreaterThan(value)) {
                    return value.toString();
                }
                else if (limit_md.isGreaterThan(value)) {
                    return value.decimalPlaces(12, 1).toString();
                }
                else if (limit_sm.isGreaterThan(value)) {
                    return value.decimalPlaces(9, 1).toString();
                }
                else if (limit_x.isGreaterThan(value)) {
                    return value.decimalPlaces(6, 1).toString();
                }
                else if (limit_xx.isGreaterThan(value)) {
                    return value.decimalPlaces(4, 1).toString();
                }
                else if (limit_xxx.isGreaterThan(value)) {
                    return value.decimalPlaces(2, 1).toString();
                }
                else {
                    return value.decimalPlaces(0, 1).toString();
                }
            }
        }
    }
</script>

<style>
    .label_left {
        float:left;
        text-align: left;
    }

    .label_right {
        float:right;
        text-align: right;
    }
    
    .event-message {
        font-size: 18px;
        color:orange;
        margin-top:20px;
    }
    .hilight {
        color:orange;       
    }
    .blue_hilight {
        color:rgb(24, 245, 72);       
    }

    .explorer div.row, .explorer div.col {
        margin:0 !important;
        padding:0 !important;
    }

    .subBlock {
        background-color: #42a1e821; 
        border-radius: 10px; 
        width:60%; 
        padding:30px; 
        margin:20px auto;
    }

    @media(max-width: 996px) {
        .subBlock {
            width:100%; 
        }

        .label_right {
            float:left;
            text-align: left;
        }
    }
</style>
